import { render, staticRenderFns } from "./PolicyInfoEditModal.vue?vue&type=template&id=1286c654&scoped=true&"
import script from "./PolicyInfoEditModal.vue?vue&type=script&lang=js&"
export * from "./PolicyInfoEditModal.vue?vue&type=script&lang=js&"
import style0 from "./PolicyInfoEditModal.vue?vue&type=style&index=0&id=1286c654&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1286c654",
  null
  
)

export default component.exports