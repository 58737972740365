<template>
  <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px" width="80%" v-model="visible" :footer="null"
           :maskClosable="false">
    <a-row>
      <a-col :span="6">
        <div style="max-height: 500px; overflow-y: scroll">
          <a-tree checkable v-model="checkedKeys" @check="onCheck" checkStrictly :treeData="permissions" @expand="onExpand" :expanded-keys="expandedKeys"
    :auto-expand-parent="autoExpandParent"> </a-tree>
        </div>
      </a-col>
      <a-col :span="18">
        <a-form-model
          ref="form"
          :model="rowData"
          :rules="formRule"
          layout="horizontal"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
        >
          <a-form-model-item label="政策名称" prop="name">
            <a-input v-model="rowData.name" :disabled="handle == 'check'" placeholder="政策名称"></a-input>
          </a-form-model-item>
          <a-form-model-item label="有效期" prop="validityStart">
            <RangePicker
              :startTime.sync="rowData.validityStart"
              :endTime.sync="rowData.validityEnd"
              :disabled-date="disabledDate"
              :disabled="handle == 'check'"
            ></RangePicker>
          </a-form-model-item>
          <a-form-model-item label="政策有效次数" prop="(flagLimit">
            <div style="display: flex; margin-top: 8px">
              <a-radio-group v-model="rowData.flagLimit" :disabled="handle == 'check'" @change="onCheckLimit">
                <a-radio :value="false">不限次数</a-radio>
                <a-radio :value="true">限制次数</a-radio>
              </a-radio-group>
              <a-input-number
                :min="1"
                v-if="rowData.flagLimit == true"
                v-model="rowData.limitCount"
                style="width: 150px"
                :disabled="handle == 'check'"
                placeholder="请输入限制次数"
              ></a-input-number>
            </div>
          </a-form-model-item>
          <a-form-model-item label="是否加入折扣" prop="joinRebate">
            <a-select v-model="rowData.joinRebate" :disabled="handle == 'check'" placeholder="请选择">
              <a-select-option v-for="(item, index) in options" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="是否参与积分" prop="joinIntegral">
            <a-select v-model="rowData.joinIntegral" :disabled="handle == 'check'" placeholder="请选择">
              <a-select-option v-for="(item, index) in options1" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="上传文件路径" prop="(fileUrl">
            <QiniuUpload type="image" :disabled="handle == 'check'" :value.sync="rowData.fileUrl"></QiniuUpload>
          </a-form-model-item>
          <a-form-model-item label="政策商品类型" prop="policyGoodsType">
            <a-radio-group v-model="rowData.policyGoodsType" :disabled="handle == 'check'" @change="onCheckPro">
              <a-radio :value="1">选择全部商品</a-radio>
              <a-radio :value="2">选择商品分类</a-radio>
              <a-radio :value="3">选择具体商品</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="折扣率" v-if="classifyType === 1 || classifyType === 2">
            <span v-if="classifyType === 1">D - </span>
            <a-input-number
              :min="1"
              :max="100"
              v-if="classifyType === 1"
              v-model="rowData.num"
              placeholder="请输入折扣率"
              :formatter="(value) => `${value}%`"
              :parser="(value) => value.replace('%', '')"
              :disabled="handle == 'check'"
            ></a-input-number>

            <a-table v-else-if="classifyType === 2" :data-source="tableData" bordered>
              <a-table-column title="分类" align="center" data-index="name"></a-table-column>
              <a-table-column title="折扣率" align="center" data-index="discountNumStr1">
                <template slot-scope="text, row">
                  D - <a-input-number
                    v-model="row.discountNumStr1"
                    :min="1"
                    :max="100"
                    :formatter="(value) => `${value}%`"
                    :parser="(value) => value.replace('%', '')"
                    :disabled="handle == 'check'"
                  ></a-input-number>
                </template>
              </a-table-column>
            </a-table>
          </a-form-model-item>
          <a-form-model-item label="选择商品" v-if="classifyType === 3">
            <a-button :disabled="handle == 'check'" type="primary" @click="onCheckProduct">添加商品</a-button>
          </a-form-model-item>
        </a-form-model>
      </a-col>
    </a-row>
    <a-table v-if="dataList.length > 0" :data-source="dataList" bordered>
      <a-table-column title="商品名称" data-index="name" align="center"></a-table-column>
      <a-table-column title="商品图片" data-index="imgUrl" align="center"></a-table-column>
<!--      <a-table-column title="建议零售价" data-index="goodsPrice" align="center"></a-table-column>-->
      <a-table-column title="品牌" data-index="brandName" align="center"></a-table-column>
      <a-table-column title="分类" data-index="categoryName" align="center"></a-table-column>
      <a-table-column title="政策价格" data-index="goodsPrice" align="center">
        <!-- <template slot-scope="text, row">
          <a-input-number
            :disabled="handle == 'check'"
            v-model="row.policyPrice"
            :min="0"
            @change="onChangePrice(row)"
          ></a-input-number>
        </template> -->
      </a-table-column>
      <a-table-column title="政策折扣率(%)" data-index="discountNumStr1" align="center" :width="150">
        <template slot-scope="text, row">
          D - <a-input-number
            :disabled="handle == 'check'"
            v-model="row.discountNumStr1"
            :min="1"
            :max="100"
            :formatter="(value) => `${value}%`"
            :parser="(value) => value.replace('%', '')"
          ></a-input-number>
        </template>
      </a-table-column>
      <a-table-column title="操作" data-index="" align="center">
        <template slot-scope="text, row">
          <a :disabled="handle == 'check'" @click="onDelete(row.goodsId)">删除</a>
        </template>
      </a-table-column>
    </a-table>
    <div class="footer-btns" v-if="handle != 'check'">
      <a-button type="default" @click="toCancel">取消</a-button>
      <a-button type="primary" @click="toSubmit">提交</a-button>
    </div>
    <ProductChoose ref="ProductChoose" @get-pruduct-list="onAddProduct"></ProductChoose>
  </a-modal>
</template>

<script>
import { editPolicyInfo, selectByIdPolicyInfo, addPolicyInfo } from '../api/PolicyInfoApi'
import moment from 'moment'
import ProductChoose from '@/views/product_tag/components/ProductChoose.vue'

export default {
  components: {
    ProductChoose,
  },
  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      // 表单验证
      formRule: {
        name: [{ required: true, message: '请输入政策名称', trigger: 'blur' }],
        validityStart: [{ required: true, message: '请输入有效期开始', trigger: 'blur' }],
        flagLimit: [{ required: true, message: '请输入是否限制次数', trigger: 'blur' }],
        limitCount: [{ required: true, message: '请输入限制次数', trigger: 'blur' }],
        joinRebate: [{ required: true, message: '请输入是否加入折扣', trigger: 'blur' }],
        joinIntegral: [{ required: true, message: '请输入是否参与积分', trigger: 'blur' }],
        fileUrl: [{ required: true, message: '请输入上传文件路径', trigger: 'blur' }],
        policyGoodsType: [{ required: true, message: '请输入政策商品类型', trigger: 'blur' }],
      },
      permissions: [],
      checkedKeys: [],
      flagLimit: 1,
      options: [
        {
          id: 1,
          name: '参与折扣',
        },
        {
          id: 0,
          name: '不参与折扣',
        },
      ],
      options1: [
        {
          id: 1,
          name: '参与积分',
        },
        {
          id: 0,
          name: '不参与积分',
        },
      ],
      tableData: [],
      classifyType: 0,
      dataList: [],
      categoryList: [],
      expandedKeys: [],
      categorylist: [],
      autoExpandParent:true
    }
  },
  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.rowData = {}
      this.$set(this.rowData, 'validityEnd', null)
      this.$set(this.rowData, 'validityStart', null)
      this.checkedKeys = []
      this.dataList = []
      this.tableData = []
      this.classifyType = 0
      if (handle === 'add') {
        this.$set(this.rowData, 'flagLimit', true)
        this.$set(this.rowData, 'num', 100)
        this.$set(this.rowData, 'joinRebate', 1)
        this.$set(this.rowData, 'joinIntegral', 1)
        this.flagLimit = true
      } else if (handle === 'edit' || handle === 'check') {
        selectByIdPolicyInfo(row.id).then((res) => {
          this.rowData = res.body
          this.checkedKeys = res.body.participateIds
          this.expandedKeys = res.body.participateIds
          if (res.body.joinIntegral) {
            this.rowData.joinIntegral = 1
          } else {
            this.rowData.joinIntegral = 0
          }
          if (res.body.joinRebate) {
            this.rowData.joinRebate = 1
          } else {
            this.rowData.joinRebate = 0
          }

          let list =
            res.body.policyGoodsDiscountWithProInfoVOList != null ? res.body.policyGoodsDiscountWithProInfoVOList : []
          this.categorylist = res.body.categoryDiscountList != null ? res.body.categoryDiscountList : []
          this.getTreeList(res.body.categoryDiscountList)
          this.dataList = list.map((i) => {
            return {
              goodsId: i.goodsId,
              name: i.proName,
              url: i.imgUrl,
              goodsPrice: i.goodsPrice,
              policyPrice: i.policyPrice,
              brandName: i.bannerName,
              categoryName: i.cateName,
              discountNumStr1: 100 - Number(i.discountNumStr),
            }
          })
          if (this.dataList.length > 0) {
            this.classifyType = 3
          } else if (res.body.categoryDiscountList != null && res.body.categoryDiscountList.length > 0) {
            this.classifyType = 2
          } else {
            this.classifyType = 1
            this.rowData.num = 100 - Number(res.body.allGoodsDiscount)
          }
        })
      }
      this.getTreeList([])
    },

    getTreeList(data) {
      this.axios.post('/api/dealer/dealer/dealerInfo/getTree').then((res) => {
        this.permissions = res.body
      })
      this.getCategoryList(data)
    },

    getCategoryList(data) {
      this.axios.get('/api/product/category/parentCategoryList').then((res) => {
        let list = res.body
        this.tableData = list.map((e) => {
          return {
            categoryId: e.id,
            name: e.name,
            discountNumStr1: 100,
          }
        })
        let item = this.tableData
        if (this.handle == 'edit' || this.handle == 'check') {
          this.tableData = item.filter((ele,index) => {
            return ele.categoryId == data[index].categoryId ? (ele.discountNumStr1 = 100 - Number(data[index].discountNumStr)) : 100
          })
        }
      })
    },

    // 监听政策价格推出政策折扣率
    onChangePrice(row) {
      this.dataList.filter((ele) => {
        if (ele.goodsId === row.goodsId) {
          return (ele.discountNumStr1 = ((row.policyPrice / row.goodsPrice) * 100).toFixed(2))
        }
      })
    },
    // 监听政策折扣率反推出政策价格
    onChangeDiscount(row) {
      this.dataList.filter((ele) => {
        if (ele.goodsId === row.goodsId) {
          return (ele.policyPrice = (row.goodsPrice * (row.discountNumStr1 / 100)).toFixed(2))
        }
      })
    },

    onCheckProduct() {
      this.$refs.ProductChoose.isShow()
    },
     onExpand(expandedKeys){
       this.expandedKeys = expandedKeys;
     },
    onCheck(keys) {
      this.rowData.participateIds = keys.checked
    },

    disabledDate(current) {
      return current && current == moment()
    },

    onAddProduct(data) {
      let vo = []
      let arr = new Map()
      data.forEach((e) => {
        vo.push({
          goodsId: e.id,
          name: e.name,
          url: e.imgUrl,
          goodsPrice: e.sellPrice,
          policyPrice: e.sellPrice,
          brandName: e.brandName,
          categoryName: e.categoryName,
          discountNumStr1: 100,
          discountNumStr: 100
        })
      })
      let list = this.dataList.concat(vo)
      this.dataList = list.filter((e) => !arr.has(e.goodsId) && arr.set(e.goodsId, 1))
    },

    // 监听是否限制次数
    onCheckLimit(val) {
      this.flagLimit = val.target.value
    },

    // 删除商品
    onDelete(id) {
      this.dataList.splice(
        this.dataList.findIndex((e) => {
          return e.id === id
        }),
        1
      )
    },

    onCheckPro(val) {
      this.classifyType = val.target.value
    },
    toCancel(){
     this.visible = false
     this.$refs.form.resetFields()
     this.$emit('reload')
    },
    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        if (this.classifyType === 1) {
          this.tableData = []
          this.dataList = []
          this.rowData.allGoodsDiscount = 100 - Number(this.rowData.num)
        } else if (this.classifyType === 2) {
          this.dataList = []
          this.rowData.num = ''
          let list = this.tableData.filter((e) => e.discountNumStr1 != 100)
          this.rowData.categoryDiscountList = list.map(e => {
            return {
              ...e,
              discountNumStr: 100 - Number(e.discountNumStr1)
            }
          })
          console.log("this.rowData.categoryDiscountList",this.rowData.categoryDiscountList)
        } else if (this.classifyType === 3) {
          this.tableData = []
          this.rowData.num = ''
          this.rowData.goodsDiscountList = this.dataList.map(e => {
            return {
              ...e,
              discountNumStr: 100 - Number(e.discountNumStr1)
            }
          })
        }

        if (this.rowData.policyGoodsType === 2) {
          if (this.rowData.categoryDiscountList.length <= 0) {
            return this.$notification.warning({ message: '请添加至少一个分类政策折扣率' })
          }
        }
        if (this.rowData.policyGoodsType === 3) {
          if (this.rowData.goodsDiscountList.length <= 0) {
            return this.$notification.warning({ message: '请添加至少一个商品' })
          }
        }
        const res = this.handle === 'add' ? await addPolicyInfo(this.rowData) : await editPolicyInfo(this.rowData)
        if (res.code === 200) {
          this.$notification.success({ message: res.message })
          this.$emit('reload')
          this.visible = false
          this.rowData = {}
        } else {
          this.$notification.error({ message: res.message })
        }
      })
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
.footer-btns {
  text-align: right;
}
</style>
